import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

import Layout from '../components/layout'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import Header from '../components/header'
import Container from '../components/container'
import SEO from '../components/seo'
import Image from '../components/images/open-jump'

const OpenJumpPage = () => (
  <Layout>
    <SEO
      title="Open Jump | Jump Around Gymnastics"
      keywords={[
        'open jump',
        'open gym',
        'jump around',
        'gymnastics free time',
        'gymnastics',
        'gymnastics madison',
        'gymnastics cottage grove',
        'gymnastics deforest',
        'gymnastics sun prairie',
        'gymnastics fall river',
        'gymnastics pardeeville',
        'gym',
      ]}
      description={`Fun, safe, and open to all ages! Check out our Open Jump times and explore our gyms.`}
    />
    <Header>
      <div className="py-16 px-2 text-white text-shadow">
        <h1 className="text-center text-5xl text-display uppercase">
          Open Jump
        </h1>
        <table className="xl:hidden table-fixed w-full md:w-4/5 mx-auto mt-8">
          <tr className="text-2xl text-display uppercase">
            <td></td>
            <td>DeForest</td>
            <td>Cottage Grove</td>
          </tr>
          <tr>
            <td className="py-2 text-2xl text-display uppercase">Tues</td>
            <td>12pm-1pm</td>
            <td>10am-12pm</td>
          </tr>
          <tr>
            <td className="py-2 text-2xl text-display uppercase">Thurs</td>
            <td>12pm-1pm</td>
            <td>10am-12pm</td>
          </tr>
          <tr>
            <td className="py-2 text-2xl text-display uppercase">Fri</td>
            <td>7:30pm-8:30pm</td>
            <td>10am-12pm</td>
          </tr>
          <tr>
            <td className="py-2 text-2xl text-display uppercase">Sat</td>
            <td>12pm-1pm</td>
            <td>10am-12pm</td>
          </tr>
        </table>

        <table className="hidden xl:table table-fixed w-full mt-8">
          <tr className="text-2xl text-display uppercase">
            <td></td>
            <td>Tues</td>
            <td>Thurs</td>
            <td>Fri</td>
            <td>Sat</td>
          </tr>
          <tr>
            <td className="py-2 text-2xl text-display uppercase">DeForest</td>
            <td>12pm-1pm</td>
            <td>12pm-1pm</td>
            <td>7:30pm-8:30pm</td>
            <td>12pm-1pm</td>
          </tr>
          <tr>
            <td className="py-2 text-2xl text-display uppercase">
              Cottage Grove
            </td>
            <td>10am-12pm</td>
            <td>10am-12pm</td>
            <td>10am-12pm</td>
            <td>10am-12pm</td>
          </tr>
        </table>
      </div>
    </Header>
    <Container>
      <div className="flex flex-wrap lg:flex-row-reverse px-4 md:px-0">
        <div className="w-full lg:w-1/2 py-12 mt-5">
          <Image
            src="openJumpImage"
            aspectRatio="fluid"
            className="picture-frame rotate-right w-4/5 m-auto"
            alt="Young girl laughing after jumping into foam pit at Open Jump"
          />
        </div>
        <div className="w-full lg:w-1/2 pb-12 md:pt-12 lg:pr-4 font-light">
          <h1 className="pb-4 font-light">Fun for All Ages</h1>
          <p className="text-grey-darker">
            Everyone is welcome to attend our Open Jumps. Open Jump offers time
            for kids to practice and learn gymnastics skills and explore Jump
            Around! Our Open Jumps are staffed by our gymnastics instructors for
            safety and supervision. Children ages 18 months &ndash; 18 years old
            are welcomed to attend. Parents of children under 6 are required to
            participate in the gym with their child. A current waiver form is
            required for all participants.
          </p>
          <div className="w-full text-center lg:flex pt-8">
            <OutboundLink
              href="https://docs.google.com/forms/d/e/1FAIpQLSdiYSBWsZlbiaiKUftzYgfr-lxzPma_0LtCWAKGrZKad4PPEw/viewform?vc=0&c=0&w=1"
              className="button-orange mx-auto"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Waiver
            </OutboundLink>
          </div>
        </div>
      </div>
    </Container>
    <Footer></Footer>
    <Copyright></Copyright>
  </Layout>
)

export default OpenJumpPage
